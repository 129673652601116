import React, { useContext } from 'react'
import AppContext from '../../AppContext'

const Time = React.forwardRef(function Time(
  props: {
    places: number
    availableThreshold: number
    fadeTop?: boolean
    fadeBottom?: boolean
    englishAsParagraph?: boolean
  } & React.HTMLProps<HTMLParagraphElement>,
  ref: React.ForwardedRef<HTMLParagraphElement>
) {
  const { darkMode } = useContext(AppContext)
  return (
    <div
      ref={ref}
      className={
        'ml-5 text-left ' +
        (props.fadeTop
          ? 'bg-gradient-to-b bg-clip-text text-transparent ' +
            (props.places === -1
              ? 'from-red-500/30 to-red-500'
              : props.places === 0
              ? 'from-red-500/30 to-red-400'
              : props.places < props.availableThreshold
              ? darkMode
                ? 'from-orange-400/20 to-orange-400'
                : 'from-orange-600/20 to-orange-600'
              : props.places >= props.availableThreshold
              ? 'from-green-400/20 to-green-400'
              : 'text-white')
          : props.fadeBottom
          ? 'bg-gradient-to-t bg-clip-text text-transparent ' +
            (props.places === -1
              ? 'from-red-500/20 to-red-500'
              : props.places === 0
              ? 'from-red-500/20 to-red-400'
              : props.places < props.availableThreshold
              ? darkMode
                ? 'from-orange-400/0 to-orange-400'
                : 'from-orange-600/0 to-orange-600'
              : props.places >= props.availableThreshold
              ? 'from-green-400/0 to-green-400'
              : 'text-white')
          : props.places === -1
          ? 'text-red-400/70'
          : props.places === 0
          ? darkMode
            ? 'text-red-500'
            : 'text-red-600'
          : props.places < props.availableThreshold
          ? darkMode
            ? 'text-orange-400'
            : 'text-orange-600'
          : props.places >= props.availableThreshold
          ? 'bg-green-1400 ' + (darkMode ? 'text-green-400' : 'text-green-500')
          : 'text-white') +
        ' ' +
        (props.className ?? '')
      }
      style={
        darkMode && !props.fadeTop && !props.fadeBottom
          ? {
              textShadow: '.03em .05em .2em rgba(0, 0, 0, 0.4)',
            }
          : {}
      }
    >
      <div
        className={
          props.places > props.availableThreshold + 1000
            ? 'bg-green-500 text-white rounded-lg p-1'
            : ''
        }
      >
        {props.places === -1 ? (
          <div>
            geschlossen
            {props.englishAsParagraph ? (
              <br />
            ) : (
              <span>&nbsp;&nbsp;&nbsp;</span>
            )}
            <span className="italic font-normal">closed</span>
          </div>
        ) : props.places === 0 ? (
          <div className="">
            ausgebucht
            {props.englishAsParagraph ? (
              <br />
            ) : (
              <span>&nbsp;&nbsp;&nbsp;</span>
            )}
            <span className="italic font-normal">sold out</span>
          </div>
        ) : props.places < props.availableThreshold ? (
          <div>
            {props.places}&nbsp;übrig
            {props.englishAsParagraph ? (
              <br />
            ) : (
              <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            )}
            <span className="italic">{props.places}&nbsp;left</span>
          </div>
        ) : (
          <div>
            verfügbar
            {props.englishAsParagraph ? (
              <br />
            ) : (
              <span>&nbsp;&nbsp;&nbsp;</span>
            )}
            <span className="italic font-normal">available</span>
          </div>
        )}
      </div>
    </div>
  )
})

export default Time
