import format from 'date-fns/format'
import { useEffect, useRef, useState } from 'react'
import { AppContextProvider } from './AppContext'
import './background.css'
import ExperienceTickets from './components/ExperienceTickets'
import Fastlane from './components/FastlaneTickets'
import WaitingRoomTickets from './components/WaitingRoomTickets'
import {
  VIEWS,
  API_URLS,
  GAP_ROWS_TO_DISPLAY,
  API_FETCH_INTERVAL,
} from './config'
import type { ExperienceGap, FastlaneGap, View } from './types'

function App() {
  const darkMode = true
  // const [language, setLanguage] = useState<Language>('de')
  const [viewToShow, setViewToShow] = useState<View>('fastlane')
  const [showInvalidViewError, setShowInvalidViewError] = useState(false)
  // const languageRef = useRef<Language>('de')
  // let languageChangeTimer = useRef<number | null>(null)
  const dataFetchInterval = useRef<number | null>(null)

  // From which number of available tickets per gap the gap should be displayed as available
  const gapsFastlaneAvailableThreshold = 20
  const gapsExperiencesAvailableThreshold = 3

  // Today’s fast lane gaps with number of available places
  const [gapsFastlane, setGapsFastlane] = useState<FastlaneGap[]>([])

  /* Current waiting time in minutes. If Wunderland API says waiting time is 120+ min, we set it to some value
  higher than 120, so that the waiting time is displayed as "120+" */
  const [waitingTime, setWaitingTime] = useState(20)

  // Today’s experience gaps
  // [<time>, <experienceCategory>, <experienceType>, <availablePlaces>]

  const [gapsExperiences, setGapsExperiences] = useState<ExperienceGap[]>([])
  // const [gapsExperiences, setGapsExperiences] = useState<ExperienceGap[]>([

  const fetchData = () => {
    // Wunderland Tickets
    fetch(API_URLS.gapsFastlane)
      .then(async (response) => {
        if (!response.ok)
          return Promise.reject('Got response status code ' + response.status)

        const isJson = response.headers
          .get('content-type')
          ?.includes('application/json')
        if (!isJson) return Promise.reject('Data is no JSON')

        const data = (await response.json()) as {
          [key: string]: { data: { from: string; available: number }[] }
        }

        if (!data) return Promise.reject('No fastlane gaps data')

        const currentTime = new Date()
        // Set time +1 minute (= datafetch interval) ahead so no past gaps are displayed
        currentTime.setMinutes(currentTime.getMinutes() + 1)
        const currentTimeDateString = format(currentTime, 'yyyy-MM-dd')
        const newGapsFastlane: FastlaneGap[] = []
        Object.keys(data).map((gapDate) => {
          data[gapDate].data
            .filter((gap) => {
              const minutes = Number(gap.from.split(':')[1])
              return minutes != 15 && minutes != 45
            })
            .map((gap) => {
              const gapTime = new Date(
                Number(gapDate.substring(0, 4)),
                Number(gapDate.substring(5, 7)) - 1,
                Number(gapDate.substring(8, 10)),
                Number(gap.from.split(':')[0]),
                Number(gap.from.split(':')[1])
              )

              if (gapTime > currentTime) {
                newGapsFastlane.push({
                  time: gapTime.getHours() + ':' + gap.from.split(':')[1],
                  available: gap.available,
                  isNextDay:
                    format(gapTime, 'yyyy-MM-dd') !== currentTimeDateString,
                })
              }
            })
        })

        setGapsFastlane(newGapsFastlane)
      })
      .catch((error) => {
        console.warn('Error fetching fastlane data: ', error)
      })

    // Experience Gaps
    fetch(API_URLS.gapsExperiences)
      .then((response) => {
        if (!response.ok) {
          return Promise.reject('Got response status code ' + response.status)
        }

        const isJson = response.headers
          .get('content-type')
          ?.includes('application/json')
        if (!isJson) {
          return Promise.reject('Data is no JSON')
        }

        return response.json()
      })
      .then(
        (data: {
          [key: string]: {
            data:
              | {
                  from: string
                  category: number
                  type: number
                  available: number
                }[]
          }
        }) => {
          if (!data) return Promise.reject('No experience gaps data')

          const currentTime = new Date()
          // Set time +1 minute (= datafetch interval) ahead so no past gaps are displayed
          currentTime.setMinutes(currentTime.getMinutes() + 1)
          const currentTimeDateString = format(currentTime, 'yyyy-MM-dd')
          const newGapsExperiences: ExperienceGap[] = []
          Object.keys(data).map((gapDate) => {
            if (gapDate === 'result' && !data[gapDate]) return
            if (!data[gapDate].data) return
            data[gapDate].data.map((gap) => {
              const gapTime = new Date(
                Number(gapDate.substring(0, 4)),
                Number(gapDate.substring(5, 7)) - 1,
                Number(gapDate.substring(8, 10)),
                Number(gap.from.split(':')[0]),
                Number(gap.from.split(':')[1])
              )

              if (gapTime > currentTime) {
                newGapsExperiences.push({
                  time: gapTime.getHours() + ':' + gap.from.split(':')[1],
                  category: gap.category,
                  type: gap.type,
                  available: gap.available,
                  isNextDay:
                    format(gapTime, 'yyyy-MM-dd') !== currentTimeDateString,
                })
              }
            })
          })

          setGapsExperiences(newGapsExperiences)
        }
      )
      .catch((error) => {
        console.warn('Error fetching experiences data: ', error)
      })

    // Waiting time
    fetch(API_URLS.waitingTime)
      .then((response) => {
        if (!response.ok) {
          return Promise.reject('Got response status code ' + response.status)
        }

        const isJson = response.headers
          .get('content-type')
          ?.includes('application/json')
        if (!isJson) {
          return Promise.reject('Data is no JSON')
        }
        return response.json()
      })
      .then((res) => {
        if (!res || !res.waitingMin)
          return Promise.reject('No waiting time data')

        // Fix for "120+" value from API
        const waitingTimeFromApi = Number(res.waitingMin)

        if (Number.isNaN(waitingTimeFromApi)) setWaitingTime(999)
        else setWaitingTime(waitingTimeFromApi)
      })
      .catch((err) => {
        console.warn('Error fetching waiting time data: ', err)
      })
  }

  // const gapIsInTheFuture = (gap: FastlaneGap | ExperienceGap) => {
  //   const now = new Date()
  //   const gapTime = new Date(
  //     now.getFullYear(),
  //     now.getMonth(),
  //     now.getDate(),
  //     parseInt(gap[0].split(':')[0]),
  //     parseInt(gap[0].split(':')[1]) - 1,
  //     30
  //   )
  //   return gapTime > now
  // }

  // Set language change timer to change every ten seconds by the clock on mount
  // const changeLanguage = () => {
  //   languageRef.current = languageRef.current === 'de' ? 'en' : 'de'
  //   setLanguage(languageRef.current)
  // }
  useEffect(() => {
    // Get the view to show from URL parameter view
    const urlParams = new URLSearchParams(window.location.search)
    const view = urlParams.get('view') as View

    // Check if view parameter has a valid value. If not, show error message
    if (!view || Object.keys(VIEWS).indexOf(view) === -1) {
      setShowInvalidViewError(true)
      return
    }
    if (showInvalidViewError) setShowInvalidViewError(false)

    setViewToShow(view)

    fetchData()

    // Refetch data always 2 seconds after the minute
    const now = new Date()
    const nextFullMinute = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      now.getHours(),
      now.getMinutes() + 1,
      0
    )

    setTimeout(() => {
      // // Set language change timer
      // if (!languageChangeTimer.current) {
      //   languageChangeTimer.current = window.setInterval(changeLanguage, 10000)
      // }
      // console.log(new Date())
      // Fetch data every 45
      dataFetchInterval.current = window.setInterval(
        fetchData,
        API_FETCH_INTERVAL
      )
    }, nextFullMinute.getTime() - now.getTime() + 2000)
    // console.log(
    //   10000 - (Date.now() % 10000),
    //   nextFullMinute.getTime() - now.getTime()
    // )
    // When timestamp 10 seconds value % 2 is 0, set language to 'de', else 'en'
    // languageRef.current =
    //   ((Math.floor(Date.now() / 10000) % 100) % 10) % 2 === 0 ? 'de' : 'en'
    // setLanguage(languageRef.current)

    // Clear timers on unmount
    return () => {
      // if (languageChangeTimer.current) {
      //   clearTimeout(languageChangeTimer.current)
      // }
      if (dataFetchInterval.current) {
        clearInterval(dataFetchInterval.current)
      }
    }
  }, [])

  if (showInvalidViewError)
    return (
      <div className="h-screen w-screen flex flex-col justify-center items-center bg-black text-gray-500 text-center text-xs">
        Ungültiger URL-Paramter view.
        <br />
        Gültige Werte: {Object.keys(VIEWS).join(', ')}
      </div>
    )
  // if (!dataFetchInterval.current)
  //   return (
  //     <div className="h-screen w-screen flex flex-col justify-center items-center bg-black text-white text-center">
  //       Zeitsynchronisation läuft - dauert{' '}
  //       {Math.ceil((5000 - (Date.now() % 10000)) / 1000) + 3} Sekunden, bitte
  //       warten...
  //     </div>
  //   )

  return (
    <AppContextProvider value={{ darkMode, language: 'de' }}>
      <div
        className={'w-screen ' + (darkMode ? 'bg-black' : 'bg-white')}
        style={{ height: '31vw' }}
      >
        <div
          className={
            'background-container opacity-50 w-screen ' +
            (darkMode ? '' : 'hidden')
          }
          style={{ height: '100vh' }}
        >
          <div className="stars"></div>
          <div className="twinkling"></div>
        </div>
        <div
          className={
            'App w-screen  ' + (darkMode ? 'text-white' : 'text-black')
          }
          style={{ height: '100vh' }}
        >
          {viewToShow === 'fastlane' ? (
            <Fastlane
              gaps={gapsFastlane}
              availableThreshold={gapsFastlaneAvailableThreshold}
              gapsRowsToShow={GAP_ROWS_TO_DISPLAY.fastlane}
            />
          ) : viewToShow === 'waitingtime' ? (
            <WaitingRoomTickets waitingTime={waitingTime} />
          ) : (
            <ExperienceTickets
              gaps={gapsExperiences}
              availableThreshold={gapsExperiencesAvailableThreshold}
              gapsRowsToShow={GAP_ROWS_TO_DISPLAY.experiences}
            />
          )}
        </div>
        {/* <div className="absolute bottom-0 opacity-0 z-0">{language}</div> */}
      </div>
    </AppContextProvider>
  )
}

export default App
