import React, { useContext } from 'react'
import AppContext from '../../AppContext'

export default function SectionBackgroundImage({
  imageUrl,
  imageOpacity = 30,
  opacity = 1.0,
}: {
  imageUrl: string
  imageOpacity: number
  opacity?: number
}) {
  const { darkMode } = useContext(AppContext)

  return (
    <div
      className={
        'inset-0 absolute h-full w-full overflow-hidden transition-opacity duration-1000 '
      }
      style={{ opacity: opacity * imageOpacity }}
    >
      <div
        className={`w-full h-full z-10 bg-center bg-cover ${
          +darkMode ? 'saturate-100' : 'saturate-150'
        }`}
        style={{
          backgroundImage: `url(${imageUrl})`,
        }}
      ></div>
    </div>
  )
}
