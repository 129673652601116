import React, { useContext } from 'react'
import AppContext from '../../AppContext'

const H1 = React.forwardRef(function H1(
  props: {} & React.HTMLProps<HTMLParagraphElement>,
  ref: React.ForwardedRef<HTMLParagraphElement>
) {
  const { darkMode } = useContext(AppContext)
  const { className, ...otherProps } = props
  return (
    <div
      ref={ref}
      {...otherProps}
      className={
        'flex flex-row mx-3 mb-2 justify-between z-20 text-lg leading-snug ' +
        (className ?? '')
      }
      style={
        darkMode
          ? {
              textShadow: '.03em .05em .2em rgba(0, 0, 0, 0.4)',
            }
          : {}
      }
    >
      {props.children}
    </div>
  )
})

export default H1
