import React, { useContext } from 'react'
import AppContext from '../AppContext'
import type { ExperienceGap } from '../types'
import Availability from './gaps/Availability'
import Time from './gaps/Time'
import Section from './sections/Section'
import SectionAvailability from './sections/SectionAvailability'
import SectionHeader from './sections/SectionHeader'
import SectionTitle from './sections/SectionTitle'
// @ts-ignore
import YullbeVideo from '../assets/start_hero.mp4'

type Props = {
  gaps: ExperienceGap[]
  availableThreshold: number
  gapsRowsToShow: number
}
function Experiences({ gaps, availableThreshold, gapsRowsToShow }: Props) {
  const { darkMode } = useContext(AppContext)

  let gapsToShow = gaps
    .filter((el) => el.available > 0)
    .filter(
      (el, index, filteredGaps) =>
        index === 0 ||
        el.time !== filteredGaps[index - 1].time ||
        el.category !== filteredGaps[index - 1]?.category
    )
    .filter((_, index) => index < gapsRowsToShow)
  // gapsToShow = []

  return (
    <Section className="basis-1/2">
      <div
        className={
          'inset-y-0 absolute min-h-full min-w-full aspect-video flex flex-col items-stretch ' +
          (darkMode ? 'saturate-150' : 'saturate-150')
        }
      >
        <video
          className={
            'w-full h-full visible scale-110 aspect-video ' +
            (darkMode ? 'opacity-50' : 'opacity-10')
          }
          preload="auto"
          loop
          autoPlay
          muted
          playsInline
        >
          <source type="video/mp4" src={YullbeVideo} />
        </video>
      </div>
      <SectionHeader>
        <div className="w-full z-20">
          <div>
            <SectionTitle>
              Erlebnis-Kombitickets&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <span className="italic">Ticket &amp; Experience Combo</span>
            </SectionTitle>
            <SectionAvailability
              className={darkMode ? 'text-green-400' : 'text-green-500'}
            >
              <div className={darkMode ? 'text-green-400' : 'text-green-600'}>
                Sofortiger Eintritt &nbsp;&nbsp;&nbsp;
                <span className="italic">Admission now</span>
              </div>

              <div className="text-right">
                Sofort verfügbar &nbsp;&nbsp;&nbsp;
                <span className="italic">Available now</span>
              </div>
            </SectionAvailability>
          </div>

          <p
            className="hidden w-1/2 text-white text-sm pl-2 mt-3 pr-2"
            style={
              darkMode
                ? {
                    textShadow: '.03em .05em .2em rgba(0, 0, 0, 0.4)',
                  }
                : {}
            }
          >
            Ergänzen Sie Ihr Ticket um eines der besonderen
            Wunderland-Erlebnisse zum Kombi-Preis!
          </p>
        </div>
      </SectionHeader>
      <div className="flex-1 z-10 flex flex-row">
        <div className="w-full flex flex-col items-center">
          <div
            className={
              'grid grid-cols-12 auto-cols-fr gap-8 text-lg mt-10 pl-6 pr-4 overflow-y-hidden border-white/40 rounded-xl ' +
              (gapsToShow.length === 0 ? ' h-full w-full' : '')
            }
          >
            {gapsToShow.length === 0 ? (
              <div className="flex-1 col-span-12 z-20 h-full text-center text-white leading-loose flex flex-col justify-center items-center">
                <div className="text-center">
                  Heute gibt es leider keine Erlebnisse mehr.
                  <br />
                  <span className="italic">
                    No more experiences available today.
                  </span>
                </div>
              </div>
            ) : (
              gapsToShow.map(
                (
                  // eslint-disable-next-line no-unused-vars
                  { time, category, type, available },
                  index,
                  filteredGaps
                ) => (
                  <>
                    <Time
                      key={time + category + type + 'time'}
                      className="col-span-2 flex flex-row items-start justify-start pr-5"
                      fadeBottom={index === gapsRowsToShow - 1}
                    >
                      <span className="text-xl">
                        {index > 0 && filteredGaps[index - 1].time === time
                          ? ' '
                          : time}
                      </span>
                    </Time>
                    <div
                      key={time + category + type + 'description'}
                      className="col-span-7 w-max flex flex-row justify-start items-start gap-10"
                    >
                      <div className="text-left">
                        <Time alignLeft={true} className="">
                          <div className="text-left">
                            inkl.{' '}
                            {category === 2
                              ? 'Virtual Reality-Erlebnis'
                              : 'Führung hinter die Kulissen'}
                          </div>
                        </Time>

                        <Time
                          className="italic text-left"
                          fadeBottom={index === gapsRowsToShow - 1}
                        >
                          <div className="text-left">
                            incl.{' '}
                            {category === 2
                              ? 'Virtual Reality experience'
                              : 'Guided tour behind the scenes'}
                          </div>
                        </Time>
                      </div>
                    </div>
                    <div
                      className="col-span-3"
                      key={time + category + type + 'availability'}
                    >
                      <Availability
                        places={available}
                        availableThreshold={availableThreshold}
                        fadeBottom={index === gapsRowsToShow - 1}
                        englishAsParagraph={true}
                      />
                    </div>
                  </>
                )
              )
            )}
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Experiences
