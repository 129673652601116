import React, { useContext } from 'react'
import AppContext from '../../AppContext'

const SectionHeader = React.forwardRef(function SectionHeader(
  props: {} & React.HTMLProps<HTMLDivElement>,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const { darkMode } = useContext(AppContext)
  const { className, children, ...otherProps } = props
  return (
    <div
      ref={ref}
      {...otherProps}
      className={
        'border-b-4 pb-3 ' +
        (darkMode ? 'border-white/30 ' : 'border-black/30 ') +
        ' bg-white/10 z-20' +
        (className ?? '')
      }
    >
      {children}
    </div>
  )
})

export default SectionHeader
