import React, { useContext } from 'react'
import AppContext from '../AppContext'
import Section from './sections/Section'
import SectionAvailability from './sections/SectionAvailability'
import SectionBackgroundImageSlider from './sections/SectionBackgroundImageSlider'
import SectionHeader from './sections/SectionHeader'
import SectionTitle from './sections/SectionTitle'

type Props = {
  waitingTime: number
}
function Fastlane({ waitingTime }: Props) {
  const { darkMode } = useContext(AppContext)

  return (
    <Section className="basis-1/4 ">
      <SectionBackgroundImageSlider
        sliderIndex={1}
        baseOpacity={darkMode ? 0.42 : 0.17}
      />

      <div className="z-20 w-full h-full flex flex-col">
        <SectionHeader>
          <SectionTitle>
            <div>
              Ohne Termin&nbsp;&nbsp;&nbsp;
              <span className="italic">Spontaneous</span>
            </div>
          </SectionTitle>
          <SectionAvailability>
            <div className={darkMode ? 'text-orange-400' : 'text-orange-600'}>
              <div className="text-left">
                Mit Wartezeit
                <br />
                <span className="italic">With waiting time</span>
              </div>
            </div>
            <div className={darkMode ? 'text-green-400' : 'text-green-600'}>
              <div className="text-right">
                Sofort verfügbar
                <br />
                <span className="italic">Available now</span>
              </div>
            </div>
          </SectionAvailability>
        </SectionHeader>
        <div className="flex-1 flex flex-col justify-center items-center">
          <div
            className={'text-lg ' + (darkMode ? 'text-gray-50' : '')}
            style={
              darkMode
                ? {
                    textShadow: '.03em .05em .2em rgba(0, 0, 0, 0.4)',
                  }
                : {}
            }
          >
            <div className="text-center leading-relaxed mb-20">
              Wartezeit aktuell voraussichtlich:
              <br />
              <span className="italic">Estimated waiting time:</span>
            </div>
          </div>
          <div
            className={
              'mt-3  text-8xl ' +
              (darkMode ? 'text-orange-400' : 'text-orange-600')
            }
            style={
              darkMode
                ? {
                    textShadow: '.03em .05em .2em rgba(0, 0, 0, 0.4)',
                  }
                : {}
            }
          >
            {waitingTime > 120
              ? '2+'
              : waitingTime / 60 > 1
              ? (Math.round((waitingTime / 60) * 2) / 2)
                  .toString()
                  .replace('.', ',')
              : waitingTime}
          </div>
          <div
            className={
              'mt-5 leading-tight text-center text-3xl ' +
              (waitingTime > 0
                ? darkMode
                  ? 'text-orange-400'
                  : 'text-orange-600'
                : '')
            }
            style={
              darkMode
                ? {
                    textShadow: '.03em .05em .2em rgba(0, 0, 0, 0.4)',
                  }
                : {}
            }
          >
            {waitingTime > 120 || waitingTime / 60 > 1 ? (
              waitingTime / 60 === 1 ? (
                <div>
                  Stunde
                  <br />
                  <span className="italic">hour</span>
                </div>
              ) : (
                <div>
                  Stunden
                  <br />
                  <span className="italic">hours</span>
                </div>
              )
            ) : (
              <div>
                Minuten
                <br />
                <span className="italic">minutes</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </Section>
  )
}

export default Fastlane
