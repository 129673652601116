import React, { useContext } from 'react'
import AppContext from '../../AppContext'

const H1 = React.forwardRef(function H1(
  props: {} & React.HTMLProps<HTMLHeadingElement>,
  ref: React.ForwardedRef<HTMLHeadingElement>
) {
  const { darkMode } = useContext(AppContext)
  const { className, ...otherProps } = props
  return (
    <h1
      ref={ref}
      {...otherProps}
      className={
        (darkMode ? 'text-white' : 'text-black') +
        ' text-center text-2xl mt-4 mb-9 ml-3 z-20 flex flex-row justify-center  ' +
        (className || '')
      }
      style={
        darkMode
          ? {
              textShadow: '.03em .05em .2em rgba(0, 0, 0, 0.4)',
            }
          : {}
      }
    >
      <span className="">{props.children}</span>
    </h1>
  )
})

export default H1
