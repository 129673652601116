export enum VIEWS {
  'fastlane' = 'fastlane',
  'waitingtime' = 'waitingtime',
  'experiences' = 'experiences',
}

export const API_URLS = {
  gapsFastlane: 'https://api.miniatur-wunderland.de/v1/t-slots/',
  gapsExperiences: 'https://api.miniatur-wunderland.de/v1/e-slots/',
  waitingTime: 'https://api.miniatur-wunderland.de/v1/current/',
}

export const API_FETCH_INTERVAL = 60000

export const GAP_ROWS_TO_DISPLAY = {
  fastlane: Math.floor(window.innerHeight * 0.0088),
  experiences: Math.floor(window.innerHeight * 0.0047),
}
