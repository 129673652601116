import React from 'react'

const Section = React.forwardRef(function Section(
  props: {} & React.HTMLProps<HTMLDivElement>,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const { className, ...otherProps } = props
  return (
    <div
      ref={ref}
      {...otherProps}
      className={
        'w-screen bg-white/0 flex flex-col relative overflow-hidden ' +
        (className ?? '')
      }
      style={{ height: '100vh' }}
    >
      {props.children}
    </div>
  )
})

export default Section
