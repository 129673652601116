import React from 'react'
import type { Language } from './types'

const AppContext = React.createContext<{
  darkMode: boolean
  language: Language
}>({ darkMode: true, language: 'en' })

export const AppContextProvider = AppContext.Provider

export default AppContext
