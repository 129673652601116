import React, { useContext } from 'react'
import AppContext from '../../AppContext'

const Time = React.forwardRef(function Time(
  props: {
    fadeTop?: boolean
    fadeBottom?: boolean
    alignLeft?: boolean
  } & React.HTMLProps<HTMLDivElement>,
  ref: React.ForwardedRef<HTMLDivElement>
) {
  const { darkMode } = useContext(AppContext)
  const { className, alignLeft, fadeTop, fadeBottom, children, ...otherProps } =
    props
  return (
    <div
      ref={ref}
      {...otherProps}
      className={
        'flex flex-row ' +
        (alignLeft ? 'justify-start ' : 'justify-end ') +
        (fadeTop
          ? 'bg-gradient-to-b bg-clip-text text-transparent ' +
            (darkMode
              ? 'from-white/0 to-white/100 '
              : 'from-black/0 to-black/100 ')
          : fadeBottom
          ? 'bg-gradient-to-t bg-clip-text text-transparent ' +
            (darkMode
              ? 'from-white/20 to-white/100 '
              : 'from-black/0 to-black/100 ')
          : darkMode
          ? 'text-gray-100'
          : 'text-gray-800') +
        ' ' +
        (className ?? '')
      }
      style={
        darkMode && !fadeTop && !fadeBottom
          ? {
              textShadow: '.03em .05em .2em rgba(0, 0, 0, 0.4)',
            }
          : {}
      }
    >
      {children}
    </div>
  )
})

export default Time
