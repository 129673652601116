import React, { useContext, useEffect, useRef, useState } from 'react'
import AppContext from '../../AppContext'
import SectionBackgroundImage from './SectionBackgroundImage'
import ImageSchweizBhf from '../../assets/schweiz-bahnhof-nacht-1024x683.jpg'
import ImageVenedigStadtNacht from '../../assets/venedig-stadtansicht-nacht-1024x668.jpg'
import ImageVesuvNacht from '../../assets/vesuv-nacht-1024x683.jpg'
import ImageVenedigKarneval from '../../assets/venedig-markusplatz-karneval-nacht-683x1024.jpg'
import ImageSkandiEiszug from '../../assets/skandinavien-eismeer-zug-1024x680.jpg'
import ImageElphi from '../../assets/elbphi-columbushaus-nacht-1024x720.jpg'
import ImageLasVegas from '../../assets/amerika-las-vegas-nacht-bearbeitet-824x1024.jpg'

export default function SectionBackgroundImageSlider({
  sliderIndex,
  baseOpacity = 1,
}: {
  sliderIndex: number
  baseOpacity: number
}) {
  const { darkMode } = useContext(AppContext)
  const imagesDark: [string, number][] = [
    [ImageSchweizBhf, 0.8],
    [ImageVenedigStadtNacht, 0.9],
    [ImageVesuvNacht, 0.9],
    [ImageVenedigKarneval, 0.7],
    [ImageSkandiEiszug, 0.67],
    [ImageElphi, 0.7],
    [ImageLasVegas, 0.7],
  ]
  const imagesLight: [string, number][] = [
    [
      'https://media.miniatur-wunderland.de/presse.de/2016/09/23145801/hamburg-michel-811x1024.jpg',
      0.8,
    ],
    [
      'https://media.miniatur-wunderland.de/presse.de/2016/09/23144631/amerika-capecanaveral-tag-1024x683.jpg',
      0.8,
    ],
    [
      'https://media.miniatur-wunderland.de/presse.de/2016/09/23150546/flughafen-terminals-tag-1024x683.jpg',
      0.8,
    ],
    [
      'https://media.miniatur-wunderland.de/presse.de/2016/09/23154053/castello-di-montebello-1024x683.jpg',
      0.8,
    ],
    [
      'https://media.miniatur-wunderland.de/presse.de/2016/09/23154301/skandinavien-eismeer-zug-1024x680.jpg',
      0.8,
    ],
    [
      'https://media.miniatur-wunderland.de/presse.de/2016/09/23164808/knuffingen-tee-frontal-1024x755.jpg',
      0.8,
    ],
    [
      'https://media.miniatur-wunderland.de/presse.de/2016/09/23172244/rom-petersdom-hf-uebersicht-683x1024.jpg',
      0.8,
    ],
  ]
  const images = darkMode ? imagesDark : imagesLight
  const imageToShowRef = useRef(Math.floor(Math.random() * (images.length - 1)))
  const [imageToShow, setImageToShow] = useState(imageToShowRef.current)
  const changeIntervalRef = useRef<number>()

  const handleImageChange = () => {
    imageToShowRef.current =
      imageToShowRef.current < images.length - 1
        ? imageToShowRef.current + 1
        : 0
    setImageToShow(imageToShowRef.current)
  }

  useEffect(() => {
    setTimeout(() => {
      if (!changeIntervalRef.current)
        changeIntervalRef.current = setInterval(
          () => handleImageChange(),
          10000
        ) as unknown as number
    }, (sliderIndex + 1) * 5000)
    return () => {
      if (changeIntervalRef.current) clearInterval(changeIntervalRef.current)
    }
  }, [])

  return (
    <div>
      {images.map(([url, opacity], index) => (
        <SectionBackgroundImage
          key={url}
          imageUrl={url}
          imageOpacity={opacity}
          opacity={imageToShow === index ? baseOpacity : 0}
        />
      ))}
    </div>
  )
}
