import format from 'date-fns/format'
import React, { useContext, useEffect, useState } from 'react'
import AppContext from '../AppContext'
import type { FastlaneGap } from '../types'
import Availability from './gaps/Availability'
import Time from './gaps/Time'
import Section from './sections/Section'
import SectionAvailability from './sections/SectionAvailability'
import SectionBackgroundImageSlider from './sections/SectionBackgroundImageSlider'
import SectionHeader from './sections/SectionHeader'
import SectionTitle from './sections/SectionTitle'

type Props = {
  gaps: FastlaneGap[]
  availableThreshold: number
  gapsRowsToShow: number
}
function Fastlane({ gaps, availableThreshold, gapsRowsToShow }: Props) {
  const { darkMode } = useContext(AppContext)
  const [showNextFreeGapRow, setShowNextFreeGapRow] = useState(false)
  const [nextFreeGapIndex, setNextFreeGapIndex] = useState(0)
  const [allGapsUnavailable, setAllGapsUnavailable] = useState(true)

  useEffect(() => {
    let index = -1
    for (let i = 0; i < gaps.length; i++) {
      if (gaps[i].available > 0) {
        index = i
        break
      }
    }

    if (index > -1) {
      setNextFreeGapIndex(index)
      setAllGapsUnavailable(false)
      setShowNextFreeGapRow(index > gapsRowsToShow - 1)
    } else if (showNextFreeGapRow) {
      setShowNextFreeGapRow(false)
    } else {
      setAllGapsUnavailable(true)
    }
  }, [gaps, gapsRowsToShow])

  const tomorrowDateString = format(new Date(), 'dd.MM..')

  return (
    <Section className="basis-1/4">
      <SectionBackgroundImageSlider
        sliderIndex={0}
        baseOpacity={darkMode ? 0.35 : 0.08}
      />

      <div className="z-20 shadow-lg w-full h-full flex flex-col">
        <SectionHeader>
          <SectionTitle>
            Mit Termin&nbsp;&nbsp;&nbsp;
            <span className="italic">Reservation</span>
          </SectionTitle>
          <SectionAvailability>
            <div className={darkMode ? 'text-green-400' : 'text-green-600'}>
              Keine Wartezeit
              <br />
              <span className="italic">No waiting time</span>
            </div>
            <div
              className={
                allGapsUnavailable
                  ? darkMode
                    ? 'text-red-500'
                    : 'text-red-600'
                  : showNextFreeGapRow
                  ? darkMode
                    ? 'text-orange-400'
                    : 'text-orange-600'
                  : darkMode
                  ? 'text-green-400'
                  : 'text-green-600'
              }
            >
              {allGapsUnavailable || nextFreeGapIndex === 0 ? (
                <div className="text-right">
                  Heute ausgebucht
                  <br />
                  <span className="italic">Sold out today</span>
                </div>
              ) : showNextFreeGapRow ? (
                <div className="text-right">
                  Verfügbar ab{' '}
                  {gaps[nextFreeGapIndex].isNextDay ? 'morgen' : ''}{' '}
                  {gaps[nextFreeGapIndex].time}
                  <br />
                  <span className="italic">
                    Available from{' '}
                    {gaps[nextFreeGapIndex].isNextDay ? 'tomorrow' : ''}{' '}
                    {gaps[nextFreeGapIndex].time}
                  </span>
                </div>
              ) : (
                <div className="text-right">
                  Verfügbar
                  <br />
                  <span className="italic">Available</span>
                </div>
              )}
            </div>
          </SectionAvailability>
        </SectionHeader>
        <div className="flex-1 flex flex-col justify-between text-lg">
          <div className="mt-8 overflow-y-hidden relative flex flex-col pb-3  z-20 rounded-lg  ">
            <div className="w-full self-center px-2 mb-1 flex flex-col space-y-4">
              {gaps
                .filter((_, index) =>
                  showNextFreeGapRow
                    ? index < gapsRowsToShow - 1
                    : index < gapsRowsToShow
                )
                .map(({ time, available }, index) => (
                  <div
                    key={time}
                    className={'grid grid-cols-3 gap-3 '}
                    style={
                      darkMode
                        ? { textShadow: '1px 1px 3px rgba(0, 0, 0, 0.5)' }
                        : {}
                    }
                  >
                    <Time
                      fadeTop={index === -1}
                      fadeBottom={
                        index ===
                        (showNextFreeGapRow
                          ? gapsRowsToShow - 2
                          : gapsRowsToShow - 1)
                      }
                    >
                      {time}
                    </Time>
                    <Availability
                      places={available}
                      availableThreshold={availableThreshold}
                      fadeTop={index === -1}
                      fadeBottom={
                        index ===
                        (showNextFreeGapRow
                          ? gapsRowsToShow - 2
                          : gapsRowsToShow - 1)
                      }
                      className="col-span-2 "
                    />
                  </div>
                ))}
            </div>
          </div>
          {showNextFreeGapRow && (
            <div
              className={
                'pb-6 w-100 flex flex-col items-center ' +
                (darkMode
                  ? 'border-white/30 border-t '
                  : 'border-black/30 border-t ')
              }
            >
              <div
                className={
                  'w-full pt-3 grid grid-cols-3 gap-3 ' +
                  (darkMode ? 'text-gray-100 ' : 'text-gray-800 ')
                }
              >
                <Time>
                  {gaps[nextFreeGapIndex].isNextDay ? tomorrowDateString : ''}{' '}
                  {gaps[nextFreeGapIndex].time}
                </Time>

                <div className="col-span-2">
                  <Availability
                    places={gaps[nextFreeGapIndex].available}
                    availableThreshold={availableThreshold}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Section>
  )
}

export default Fastlane
